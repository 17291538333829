import React from 'react';

const VideoPlayer = ({ src, type }) => {
  return (
    <div style={{ position: 'relative', width: '100%', height: 'auto' }}>
      <video
        src={src}
        type={type}
        style={{ width: '100%', height: 'auto' }}
        controls={false}
        autoPlay
        muted
        loop
      />
    </div>
  );
};

export default VideoPlayer;
