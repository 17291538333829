import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../component/Header';
import Footer from '../component/Footer';

export default function Contact() {
  const [isAgreed, setIsAgreed] = useState(false);

  const handleToggle = () => {
    setIsAgreed(prev => !prev);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    
    // Collect form data
    const formData = new FormData(event.target);
    const data = {
      firstName: formData.get('first-name'),
      lastName: formData.get('last-name'),
      company: formData.get('company') || '', // Optional field
      email: formData.get('email'),
      phoneNumber: formData.get('phone-number') || '', // Optional field
      message: formData.get('message')
    };
    
    // Validate required fields
    const requiredFields = ['firstName', 'lastName', 'email', 'message'];
    const isValid = requiredFields.every(field => data[field]);

    if (isValid) {
      alert("Your request has been submited");
    } else {
      alert('Please fill in all required fields.');
    }
  };

  return (
    <div className="isolate bg-white px-6 pt-24 sm:pt-32 lg:px-8">
      <Header name="Contact Us" />
      <div 
        className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]" 
        aria-hidden="true">
        <div 
          className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#E85400] to-[#FF8720] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
          style={{
            clipPath: 'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)'
          }}>
        </div>
      </div>
      <div className="mx-auto max-w-2xl text-center">

        <p className="mt-2 text-lg leading-8 text-gray-600">Your message will be sent to us directly! <br/>we'll respond as soon as possible..</p>
      </div>
      <form onSubmit={handleSubmit} className="mx-auto mt-16 max-w-xl sm:mt-20">
        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
          <div>
            <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-gray-900">First name</label>
            <div className="mt-2.5">
              <input type="text" name="first-name" id="first-name" autoComplete="given-name" required className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#E85400] outline-none sm:text-sm sm:leading-6"/>
            </div>
          </div>
          <div>
            <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-gray-900">Last name</label>
            <div className="mt-2.5">
              <input type="text" name="last-name" id="last-name" autoComplete="family-name" required className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#E85400] outline-none sm:text-sm sm:leading-6"/>
            </div>
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="company" className="block text-sm font-semibold leading-6 text-gray-900">Company</label>
            <div className="mt-2.5">
              <input type="text" name="company" id="company" autoComplete="organization" className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#E85400] outline-none sm:text-sm sm:leading-6"/>
            </div>
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">Email</label>
            <div className="mt-2.5">
              <input type="email" name="email" id="email" autoComplete="email" required className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#E85400] outline-none sm:text-sm sm:leading-6"/>
            </div>
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="phone-number" className="block text-sm font-semibold leading-6 text-gray-900">Phone number</label>
            <div className="relative mt-2.5">
              <div className="absolute inset-y-0 left-0 flex items-center">
                <label htmlFor="country" className="sr-only">Country</label>
                <select id="country" name="country" className="h-full rounded-md border-0 bg-transparent bg-none py-0 pl-4 pr-9 text-gray-400 focus:ring-2 focus:ring-inset  focus:ring-[#E85400] sm:text-sm">
                  <option>US</option>
                  <option>CA</option>
                  <option>EU</option>
                </select>
              </div>
              <input type="tel" name="phone-number" id="phone-number" autoComplete="tel" className="block w-full rounded-md border-0 px-3.5 py-2 pl-20 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#E85400] outline-none sm:text-sm sm:leading-6"/>
            </div>
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-900">Message</label>
            <div className="mt-2.5">
              <textarea name="message" id="message" rows="4" required className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#E85400] outline-none sm:text-sm sm:leading-6"></textarea>
            </div>
          </div>
          <div className="flex gap-x-4 sm:col-span-2">
            <div className="flex h-6 items-center">
              <button 
                type="button" 
                onClick={handleToggle} 
                className={`flex w-8 flex-none cursor-pointer rounded-full bg-gray-200 p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out ${isAgreed ? '!bg-[#E85400]' : ''}`}
                role="switch" 
                aria-checked={isAgreed} 
                aria-labelledby="switch-1-label"
              >
                <span className="sr-only">Agree to policies</span>
                <span aria-hidden="true" className={`h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out ${isAgreed ? 'translate-x-4' : 'translate-x-0'}`}></span>
              </button>
            </div>
            <label className="text-sm leading-6 text-gray-600" id="switch-1-label">
              By selecting this, you agree to our
              <Link to="/privacypolicy"><span className="font-semibold text-[#E85400]"> privacy&nbsp;policy</span>.</Link>
            </label>
          </div>
        </div>
        <div className="mt-10">
          <button type="submit" className="block w-full rounded-md bg-gradient-to-r from-[#E85400] to-[#FF8720]  px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">Let's talk</button>
        </div>
      </form>
      <Footer/>
    </div>
  );
}
