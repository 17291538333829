import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Back from '../assets/back.svg'
const Header = ({name}) => {
  const [showHeader, setShowHeader] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    let lastScrollTop = 0;
    const handleScroll = () => {
      const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollTop > lastScrollTop) {
        setShowHeader(false);
      } else {
        setShowHeader(true);
      }
      lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop;
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header className={`fixed top-0 left-0 w-full bg-white shadow-md transition-transform duration-300 ${showHeader ? 'translate-y-0' : '-translate-y-full'}`}>
      <div className="flex items-center justify-between p-4 gap-3">
        <img onClick={() => navigate(-1)} src={Back} alt="back button" className='!w-4'/>
        <h1 className="text-sm sm:text-xl font-semibold text-gray-900">{name}</h1>
        <div></div> {/* Empty div for alignment */}
      </div>
    </header>
  );
};

export default Header;
