import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import TermsAndCondition from './Pages/TermsAndCondition';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import Contact from './Pages/Contact';
import About from './Pages/About';
import BetaTester from './Pages/BetaTester';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />} />  
        <Route path="/contact" element={<Contact />} /> 
        <Route path="/about" element={<About />} /> 
        <Route path="/termsandcondition" element={<TermsAndCondition />} /> 
        <Route path="/privacypolicy" element={<PrivacyPolicy />} /> 
        <Route path="/betatesting" element={<BetaTester />} />  



        Terms&Condition
      </Routes>
    </Router>
  </React.StrictMode>
);

reportWebVitals();
